import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IntercomUtils from '../../../utils/IntercomUtils';
import HelpIcon from '../../shared/icon/HelpIcon';
import Tooltip from '../../shared/Tooltip';
import { useProductFruitsApi } from 'react-product-fruits';

const HelpTrigger: FC = () => {
  const { t } = useTranslation('common');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [lifeRingButton, setLifeRingButton] = useState<any>();
  const [isLifeRingOpen, setIslifeRingOpen] = useState(false);
  const onClick = useCallback(() => {
    if (import.meta.env.VITE_INTERCOM_APP_ID) {
      IntercomUtils.show();
    } else if (import.meta.env.VITE_PRODUCT_FRUIT_WORKSPACE_CODE) {
      const hubspotHolder = document.getElementById('hubspot-chat-holder');
      if (lifeRingButton) {
        if (isLifeRingOpen) {
          lifeRingButton.close();
          if (hubspotHolder && hubspotHolder.style.visibility) {
            hubspotHolder.style.visibility = 'hidden';
          }
        } else {
          if (!(hubspotHolder && !hubspotHolder.style.visibility)) {
            lifeRingButton.open();
          } else {
            hubspotHolder.style.visibility = 'hidden';
          }
        }
      }
    } else {
      window.open('mailto:support@playbk.io', '_blank');
    }
  }, [isLifeRingOpen, lifeRingButton]);

  useEffect(() => {
    if (lifeRingButton) {
      lifeRingButton.listen('opened', () => {
        lifeRingButton.showButton();
        setIslifeRingOpen(true);
      });
      lifeRingButton.listen('closed', () => {
        setIslifeRingOpen(false);
        lifeRingButton.hideButton();
      });
    }
  }, [lifeRingButton]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useProductFruitsApi((api: any) => {
    setLifeRingButton(api.button);
  }, []);

  return (
    <Tooltip text={t('help-tooltip')}>
      {(tooltip) => (
        <div {...tooltip}>
          <HelpIcon onClick={onClick} className="h-7 w-7 rounded-full border-2 border-black bg-white p-1 text-black" />
        </div>
      )}
    </Tooltip>
  );
};

export default HelpTrigger;
